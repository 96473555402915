import React, { useEffect, useState } from 'react';
import components from 'components';
import i18n from 'i18next';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { interventionsOperations, interventionsSelectors } from 'railfleet_state/ducks/interventions';
import { assetsOperations, assetsSelectors } from 'railfleet_state/ducks/assets';
import { companiesSelectors } from 'railfleet_state/ducks/companies';
import { locationsSelectors } from 'railfleet_state/ducks/locations/';

import './InterventionForm.scss';
import scrollToTop from '../../../utils/scrollToTop';
import EventsSelected from '../../lists/eventsSelected/EventsSelected';
import useFetch from '../../../components/hooks/useFetch';
import useArrayToDict from '../../../components/hooks/useArrayToDict';

const {
    FormManager,
    FormDateTime,
    FormTextInput,
    FormCustomField,
    FormLocationDropdown,
    CutForm,
    InputsWrapper,
    FormDropdown,
} = components.forms;
const { TextDisplay } = components.display;

const { THEMES } = components.selectors;
const { MultiDeviceLayout } = components.app;
const { usePerm } = components.hooks;

const InterventionForm = (props) => {
    const [_sent, setSent] = useState(false);
    const [_selectedFrom, setSelectedFrom] = useState(null);
    const [_assignTo, setAssignTo] = useState(null);
    const [_iea, setIea] = useState([]);
    const [_errorApi, setErrorApi] = useState(null);
    const [_interventionType, setInterventionType] = useState(null);
    const [_workshops, setWorkshops] = useState(null);
    const workshops = useArrayToDict(_workshops, 'id');
    const [_selectedWorkshops, setSelectedWorkshops] = useState(null);
    const [_defaultPoi, setDefaultPoi] = useState(null);
    const { fetchAll } = useFetch();
    const hasPerm = usePerm(props.asset_id || null);
    if (!props.intervention_id && !hasPerm('create_intervention') && !props.loginless) return (<div>YOU DO NOT HAVE THE PERM: create_intervention</div>);
    if (props.intervention_id && !hasPerm('edit_intervention') && !props.loginless) return (<div>YOU DO NOT HAVE THE PERM: edit_intervention</div>);

    useEffect(() => {
        window.pausePolling(['all_assets_live_data']);
        if (window.pause_timeline_polling) {
            window.pause_timeline_polling();
        }
        return () => {
            window.unpausePolling(['all_assets_live_data']);
            if (window.unpause_timeline_polling) {
                window.unpause_timeline_polling();
            }
        };
    }, []);

    useEffect(() => {
        if (props.ecm_id) {
            fetchAll('/api/workshop.json', {
                company: props.ecm_id,
            }).then((d) => {
                if (d) {
                    setWorkshops(d);
                } else {
                    setWorkshops(null);
                }
            });
        } else {
            setWorkshops(null);
        }
    }, [props.ecm_id]);

    useEffect(() => {
        const iea = [];
        if (props.events && props.events.length > 0) {
            for (const event_id of props.events) {
                iea.push({
                    event: event_id,
                });
            }
        }
        setIea(iea);
    }, [props.events]);

    useEffect(() => {
        if (props.asset_id) {
            props.fetchAssignToForAsset(props.asset_id);
        }
    }, [props.asset_id]);

    useEffect(() => {
        if (props.assign_to && !_assignTo) {
            setAssignTo(props.assign_to);
        }
    }, [props.assign_to]);

    const defaultValues = props.intervention || {};

    const format_data = (data) => {
        const formated_data = { ...data, ...props.datesTimeline };
        if (props.intervention && props.intervention.id) {
            formated_data.id = props.intervention.id;
        } else {
            formated_data.date_start_planned = data.date_start_planned.toISOString();
            formated_data.date_done_planned = data.date_done_planned.toISOString();
            formated_data.interventioneventaction = _iea;
            formated_data.events = props.events;
            formated_data.requested_by = props.user.id;
            formated_data.asset = props.asset_id;
            if (data.unconfirmed) {
                formated_data.state = 'unconfirmed';
            }
        }
        if (props.intervention && data.date_start_planned.format('DD/MM/YYYY HH:mm:ss') === moment(props.intervention.date_start_planned).format('DD/MM/YYYY HH:mm:ss')) delete formated_data.date_start_planned;
        if (props.intervention && data.date_done_planned.format('DD/MM/YYYY HH:mm:ss') === moment(props.intervention.date_done_planned).format('DD/MM/YYYY HH:mm:ss')) delete formated_data.date_done_planned;
        return formated_data;
    };

    const submit = (data) => {
        if (_sent) return;
        const formated_data = format_data(data);
        setSent(true);
        if (!props.intervention_id) {
            props.createIntervention(formated_data).then((d) => {
                if (d.payload && !d.error) {
                    setErrorApi(null);
                    if (props.callback) props.callback(d.payload.id);
                }
                if (d.error) {
                    setErrorApi(d.payload.response);
                    scrollToTop();
                }
                setSent(false);
            });
        } else {
            props.updateIntervention(formated_data).then((d) => {
                if (d.payload && !d.error) {
                    setErrorApi(null);
                    if (props.callback) props.callback(d.payload);
                }
                if (d.error) {
                    setErrorApi(d.payload.response);
                    scrollToTop();
                }
                setSent(false);
            });
        }
    };

    const onSecondary = (data) => {
        submit({ ...data, unconfirmed: true });
    };

    const onSubmit = (data) => {
        submit(data);
    };

    const onCancel = () => {
        if (props.cancelCallback) props.cancelCallback();
    };

    const onChangeFrom = (arg) => {
        if (arg) setSelectedFrom(arg);
        else setSelectedFrom(null);
    };

    return (
        <div className={`form-main ${props.device} intervention-form`}>
            <FormManager
                onSubmit={onSubmit}
                onSecondary={onSecondary}
                textSubmitButton={props.intervention_id ? i18n.t('Update intervention') : i18n.t('Create intervention')}
                textSecondaryButton={!props.intervention_id && i18n.t('Create as unconfirmed')}
                disabled={_sent}
                onCancel={onCancel}
                apiErrors={_errorApi}
                primary
            >
                <InputsWrapper
                    id="general"
                    title={i18n.t('General')}
                >
                    <CutForm
                        device={props.device}
                    >
                        <TextDisplay
                            label={i18n.t('Asset')}
                            data={props.asset_name}
                        />
                        <FormDropdown
                            id="intervention_type"
                            defaultValue={
                                defaultValues
                                && defaultValues.intervention_type
                                && defaultValues.intervention_type
                            }
                            label={i18n.t('Intervention Type')}
                            values={props.intervention_types}
                            id_getter={(a) => a.id}
                            labelizer={(a) => a.name}
                            clearable
                            required
                            theme={THEMES.FORM_LIGHT}
                            disabled={!!props.intervention.date_start_actual}
                            onChange={(arg) => setInterventionType(arg)}
                        />
                        <FormDropdown
                            id="workshop_in_charge"
                            value={
                                defaultValues
                                && defaultValues.workshop_in_charge
                            }
                            label={i18n.t('Assigned To')}
                            values={_workshops}
                            id_getter={(a) => a.id}
                            labelizer={(a) => a.name}
                            clearable
                            required
                            theme={THEMES.FORM_LIGHT}
                            onChange={(arg) => {
                                if (arg) {
                                    const w = workshops[arg];
                                    setDefaultPoi(w && w.default_poi);
                                }
                                setSelectedWorkshops(arg);
                            }}
                            disable={!_interventionType}
                        />
                        <FormDateTime
                            id="date_start_planned"
                            defaultValue={
                                (defaultValues
                                && defaultValues.date_start_planned
                                && moment(defaultValues.date_start_planned))
                                || moment()
                            }
                            label={i18n.t('Planned Start Date')}
                            className="trs-datepicker-container"
                            fillFlex
                            required
                            onChange={onChangeFrom}
                        />
                        <FormDateTime
                            id="date_done_planned"
                            defaultValue={
                                (defaultValues
                                && defaultValues.date_done_planned
                                && moment(defaultValues.date_done_planned))
                                || moment().add(1, 'days')
                            }
                            label={i18n.t('Planned End Date')}
                            className="trs-datepicker-container"
                            minDate={_selectedFrom}
                            fillFlex
                            required
                        />
                        {
                            _selectedWorkshops ? (
                                <FormLocationDropdown
                                    id="poi"
                                    defaultValue={(defaultValues && defaultValues.poi) || _defaultPoi}
                                    label={i18n.t('Intervention location')}
                                    id_getter={(item) => item.value}
                                    labelizer={(item) => item.label}
                                    clearable
                                    required
                                    theme={THEMES.FORM_LIGHT}
                                    asset_id={props.asset_id}
                                    assign_to={_selectedWorkshops}
                                    asset_class={props.asset_class}
                                    autoFilled
                                />
                            ) : (
                                <FormDropdown
                                    id="poi"
                                    label={i18n.t('Intervention location')}
                                    theme={THEMES.FORM_LIGHT}
                                    readOnly
                                />
                            )
                        }
                    </CutForm>
                    <div className="event-form-full-line">
                        <FormTextInput
                            id="comment"
                            defaultValue={defaultValues && defaultValues.comment}
                            label={i18n.t('Comment')}
                            large
                            lockSize
                        />
                    </div>
                    <FormCustomField
                        id="public_fields"
                        asset={props.asset_id}
                        defaultValue={defaultValues}
                        public
                        content_type="intervention"
                    />
                </InputsWrapper>
                {
                    props.display_private
                        && (
                            <InputsWrapper
                                id="private"
                                title={i18n.t('Private')}
                                private
                            >
                                <FormCustomField
                                    id="private_fields"
                                    defaultValue={defaultValues}
                                    private
                                    content_type="intervention"
                                />
                            </InputsWrapper>
                        )
                }
            </FormManager>
            <EventsSelected
                asset_id={props.asset_id}
                event_ids={props.events}
            />
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { intervention_id } = props;
    const { asset_id } = props;
    const intervention = intervention_id
        ? interventionsSelectors.getInterventionById(state, intervention_id)
        : null;
    const location_id = intervention && intervention.poi;
    const created_by_id = intervention && intervention.created_by;
    const comment = intervention && intervention.comment;

    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const asset_name = asset && asset.name;
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = asset_id && getMaintenanceSettings(state, asset_id);

    const intervention_types = maintenance_settings && maintenance_settings.intervention_type;
    const intervention_type_id = intervention && intervention.intervention_type;

    const intervention_type_name = intervention_types && intervention_type_id
        && intervention_types[intervention_type_id] && intervention_types[intervention_type_id].name;

    const companies = companiesSelectors.getCompanies(state);
    const assign_to = asset && asset.assign_to;
    const locations = locationsSelectors.getLocations(state);

    const location_name = locations && location_id
        && locations[location_id] && locations[location_id].name;
    const users = usersSelectors.getUsers(state);
    const created_by = created_by_id && users
        && users[created_by_id] && users[created_by_id].full_name;
    const private_fields = companiesSettingsSelectors.getPrivateFieldsForUserWithContentType('intervention')(state);
    const asset_class = asset && asset.asset_class;
    return {
        asset_class,
        intervention: intervention || {},
        asset_name,
        intervention_type_name,
        intervention_types,
        assign_to,
        location_name,
        created_by,
        comment,
        companies,
        display_private: private_fields && private_fields.length > 0,
        user: state.users.me,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchAssignToForAsset: (asset_id) => dispatch(
        assetsOperations.fetchAssignTo(asset_id),
    ),
    fetchInterventionDetail: (intervention_id) => dispatch(
        interventionsOperations.fetchDetails(intervention_id),
    ),
    createIntervention: (values) => dispatch(
        interventionsOperations.createIntervention(values),
    ),
    updateIntervention: (values) => dispatch(
        interventionsOperations.updateIntervention(values),
    ),
});

const Comp = (props) => (
    <MultiDeviceLayout>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <InterventionForm
            {...props}
        />
    </MultiDeviceLayout>
);

export default connect(mapStateToProps, mapDispatchToProps)(Comp);
