/**
 * Created by lpostula on 15/05/17.
 */
import { RSAA } from 'redux-api-middleware';
import Cookies from 'js-cookie';

import types from './types';
import { API_ROOT } from '../../config';

const API_RESOURCE_NAME = 'maintenance';

const fetchListFiltered = (filter_name, filter_value, filter_result, controller = null, limit = 50) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/sliced_list.json?${filter_name}=${filter_value}&limit=${limit}`,
        method: 'GET',
        credentials: 'same-origin',
        options: {
            signal: controller && controller.signal,
        },
        types: [
            types.FETCH_LIST_REQUEST,
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: filter_result ? {
                    filter_out: { key: filter_name, value: filter_value },
                } : null,
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export const fetchForAsset = (asset_id, controller = null, limit = 50) => fetchListFiltered('asset', asset_id, true, controller, limit);
export const fetchForIntervention = (intervention_id, controller = null) => fetchListFiltered('intervention', intervention_id, true, controller);
export const fetchForCampaign = (campaign_id, controller = null, limit = 50) => fetchListFiltered('campaign', campaign_id, true, controller, limit);
export const fetchByIds = (event_ids, controller = null) => fetchListFiltered('ids', event_ids.join(','), false, controller);

export const fetchDetails = (item_id) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${item_id}.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            types.FETCH_DETAILS_REQUEST,
            types.FETCH_DETAILS_COMPLETED,
            types.FETCH_DETAILS_FAILED,
        ],
    },
});

export const fetchList = (long_polling = false) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/sliced_list.json`,
        method: 'GET',
        credentials: 'same-origin',
        types: [
            {
                type: types.FETCH_LIST_REQUEST,
                meta: { long_polling },
            },
            {
                type: types.FETCH_LIST_COMPLETED,
                meta: { long_polling },
            },
            types.FETCH_LIST_FAILED,
        ],
    },
});

export function runPreventiveScheduler(asset) {
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: `${API_ROOT + API_RESOURCE_NAME}/schedule/`,
                method: 'POST',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
                },
                body: JSON.stringify({ asset }),
                credentials: 'same-origin',
                types: [
                    {
                        type: types.RUN_SCHEDULER_REQUEST,
                        meta: { asset },
                    },
                    {
                        type: types.RUN_SCHEDULER_COMPLETED,
                        payload: (action, state, res) => res.json().then((json) => {
                            if (res.ok) {
                                dispatch(fetchForAsset(asset));
                            }
                            return json;
                        }),
                        meta: { asset },
                    },
                    {
                        type: types.RUN_SCHEDULER_FAILED,
                        meta: { asset },
                    },
                ],
            },
        });
        return Promise.resolve();
    };
}

export const closeMaintenanceEvent = (values) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}/close.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CLOSE_EVENT_REQUEST,
            types.CLOSE_EVENT_COMPLETED,
            types.CLOSE_EVENT_FAILED,
        ],
    },
});

export const processMaintenanceEvent = (values) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}/process.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.PROCESS_EVENT_REQUEST,
            types.PROCESS_EVENT_COMPLETED,
            types.PROCESS_EVENT_FAILED,
        ],
    },
});

export const updateMaintenanceEvent = (values) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${values.id}.json`,
        method: 'PATCH',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.UPDATE_DETAILS_REQUEST,
            types.UPDATE_DETAILS_COMPLETED,
            types.UPDATE_DETAILS_FAILED,
        ],
    },
});

export const createMaintenanceEvent = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_EVENT_REQUEST,
            types.CREATE_EVENT_COMPLETED,
            types.CREATE_EVENT_FAILED,
        ],
    },
});

export const updateEventInStore = (data) => ({
    type: types.CREATE_EVENT_COMPLETED,
    payload: data,
});

export const discardEventInStore = (event_id) => ({
    type: types.DELETE_EVENT_COMPLETED,
    meta: { id: event_id },
});

export const discardEvent = (event_id) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${event_id}/`,
        method: 'DELETE',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        credentials: 'same-origin',
        types: [
            types.DELETE_EVENT_REQUEST,
            {
                type: types.DELETE_EVENT_COMPLETED,
                meta: { id: event_id },
            },
            types.DELETE_EVENT_FAILED,
        ],
    },
});

export const assignFaultCategoryToEvent = (id, fault_category_id) => (dispatch) => dispatch({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/${id}/assign_fault_category.json`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(fault_category_id),
        credentials: 'same-origin',
        types: [
            types.ASSIGN_FAULT_CAT_REQUEST,
            types.ASSIGN_FAULT_CAT_COMPLETED,
            types.ASSIGN_FAULT_CAT_FAILED,
        ],
    },
});

export const createEvent = (values) => ({
    [RSAA]: {
        endpoint: `${API_ROOT + API_RESOURCE_NAME}/`,
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
        },
        body: JSON.stringify(values),
        credentials: 'same-origin',
        types: [
            types.CREATE_EVENT_REQUEST,
            types.CREATE_EVENT_COMPLETED,
            types.CREATE_EVENT_FAILED,
        ],
    },
});

export default {
    fetchForAsset,
    fetchForIntervention,
    fetchForCampaign,
    fetchByIds,
    fetchDetails,
    fetchList,
    closeMaintenanceEvent,
    processMaintenanceEvent,
    updateMaintenanceEvent,
    createMaintenanceEvent,
    runPreventiveScheduler,
    discardEvent,
    updateEventInStore,
    discardEventInStore,
    assignFaultCategoryToEvent,
    createEvent,
};
