/* eslint-disable max-len */
import React from 'react';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { countersSelectors } from 'railfleet_state/ducks/counters';
import { usersSelectors } from 'railfleet_state/ducks/users';
import railfleet from 'railfleet';
import formatNumber from 'railfleet/utils/formatNumber';
import {
    filteringCollectionsOperations,
    filteringCollectionsSelectors,
} from 'railfleet_state/ducks/filtering_collections';

import './events.scss';
import './event.scss';
import CompactDuplicateDisplay
    from 'compounds/duplicateTicketDetection/compactDuplicateDisplay/CompactDuplicateDisplay';
import { COUNTER_UNIT_SHORT_TRANSLATION } from '../constants';
import SmallDocumentHolder from './smalDocumentHolder';

const { FlexTable } = railfleet.components.tables.FlexTable;
const {
    Table,
} = railfleet.components.tables;

const MaintenanceStateTranslation = {
    open: i18n.t('open'),
    unplanned: i18n.t('unplanned'),
    planned: i18n.t('planned'),
    in_progress: i18n.t('in_progress'),
    closed: i18n.t('closed'),
    due: i18n.t('due'),
    overdue: i18n.t('overdue'),
    due_tl: i18n.t('due_tl'),
    overdue_tl: i18n.t('overdue_tl'),
    init: i18n.t('init'),
};

const MaintenanceTypeTranslation = {
    corrective: i18n.t('corrective'),
    preventive: i18n.t('preventive'),
    servicing: i18n.t('servicing'),
    campaign: i18n.t('campaign'),
};

class _MaintenanceEventListClass extends React.Component {
    constructor(props) {
        super(props);
        // eslint-disable-next-line no-unneeded-ternary
        const expanded = localStorage.getItem('board.expanded') === 'false' ? false : true;
        this.state = {
            maintenanceEvents: null,
            expanded,
        };
        this.in14days = moment().endOf('day').add(14, 'days');
        this.assetRenderer = this.assetRenderer.bind(this);
        this.assetClassRenderer = this.assetClassRenderer.bind(this);
        this.userRenderer = this.userRenderer.bind(this);
        this.circumstancesRenderer = this.circumstancesRenderer.bind(this);
        this.descriptionRenderer = this.descriptionRenderer.bind(this);
        this.smallDocumentRenderer = this.smallDocumentRenderer.bind(this);
        this.impactStatusRenderer = this.impactStatusRenderer.bind(this);
        this.dateOpenedRenderer = this.dateOpenedRenderer.bind(this);
        this.sortByDateAsc = this.sortByDateAsc.bind(this);
        this.sortByDateDesc = this.sortByDateDesc.bind(this);
        this.expandCallback = this.expandCallback.bind(this);
        this.remainingUsageRenderer = this.remainingUsageRenderer.bind(this);
        this.maintenanceDateStateRenderer = this.maintenanceDateStateRenderer.bind(this);
    }

    sortByDateAsc(a, b) {
        if (this.props.show_date_opened) {
            if (a.state === 'open') {
                return moment(a.date_opened).diff(moment(b.date_opened));
            }
            return moment(a.dated_state.date).diff(moment(b.dated_state.date));
        }
        return moment(a.dated_state.date).diff(moment(b.dated_state.date));
    }

    sortByDateDesc(a, b) {
        if (this.props.show_date_opened) {
            if (a.state === 'open') {
                return moment(b.date_opened).diff(moment(a.date_opened));
            }
            return moment(b.dated_state.date).diff(moment(a.dated_state.date));
        }
        return moment(b.dated_state.date).diff(moment(a.dated_state.date));
    }

    eventIdRenderer(cellData, rowData) {
        if (rowData.private) {
            // code below is an event id with a vertically aligned icon
            // that doesn't break when the layout switch in vertical stack
            return (
                <div>
                    <span>
                        {`M${cellData}`}
                    </span>
                    <span
                        style={{ lineHeight: '8px' }}
                        className="glyphicons glyphicons-eye-close"
                    />
                </div>
            );
        }
        return `M${cellData}`;
    }

    maintenanceTypeRenderer(cellData) {
        return MaintenanceTypeTranslation[cellData];
    }

    assetRenderer(cellData, rowData) {
        const asset = this.props.assets[rowData.asset];
        if (asset) {
            return asset.name;
        }
        return null;
    }

    assetClassRenderer(cellData, rowData) {
        const asset = this.props.assets[rowData.asset];
        if (asset) {
            return asset.class_name;
        }
        return null;
    } // todo migrate this to asset_class

    userRenderer(cellData) {
        let name = this.props.users[cellData] != null ? this.props.users[cellData].full_name : null;
        if (name) {
            name = name.replace('@', `@${String.fromCharCode('8203')}`);
            name = name.replace('.', `.${String.fromCharCode('8203')}`);
        }
        return name;
    }

    circumstancesRenderer(cellData) {
        const cir_text = cellData ? _.map(cellData, (cir) => (
            this.props.maintenance_settings.event_circumstance_type[cir] != null
                ? this.props.maintenance_settings.event_circumstance_type[cir].name : null
        )).join(', ') : '-';
        return (
            <span title={cir_text}>
                {cir_text}
            </span>
        );
    }

    interventionRenderer(cellData) {
        return (
            <span>
                {
                    cellData != null ? (
                        `IN${cellData}`
                    ) : '-'
                }
            </span>
        );
    }

    descriptionRenderer(cellData, rowData) {
        let inf = '';
        if (rowData.counter) {
            const cnt = this.props.counters[rowData.counter];
            if (cnt != null ? cnt.serial : null) {
                inf = ` (${cnt.serial})`;
            }
        }

        const filtered_duplicat = [];
        // eslint-disable-next-line chai-friendly/no-unused-expressions
        this.props.duplicat && Object.keys(this.props.duplicat).forEach((key) => {
            const duplicate = this.props.duplicat[key];
            const filtered = duplicate.filter((item) => item?.event_1 === rowData.id || item?.event_2 === rowData.id);
            if (filtered?.length) {
                filtered_duplicat.push(...filtered);
            }
        });
        const duplicat = _.filter(filtered_duplicat, (element) => element.status === 'candidate_duplicate');
        const confirmed_duplicat = _.filter(filtered_duplicat, (element) => element.status === 'confirmed_duplicate');
        return (
            <div className="description-renderer">
                <div>{cellData + inf}</div>
                <CompactDuplicateDisplay
                    duplicat={duplicat}
                    confirmed_duplicat={confirmed_duplicat}
                />
            </div>
        );
    }

    // helper for additional info render
    renderInfo(label, content) {
        return (
            <div>
                <div className="additional-info-label">
                    {label}
                </div>
                <div title={content}>
                    {content}
                </div>
            </div>
        );
    }

    smallDocumentRenderer(cellData, rowData) {
        const { maintenance_type } = rowData;
        const asset_id = rowData.asset;
        return (
            <SmallDocumentHolder
                event_id={rowData.id}
                count={(cellData != null ? cellData.length : null) || 0}
                disabled={!this.props.user_perm_check(`edit_${maintenance_type}_event`, asset_id)}
            />
        );
    }

    dateOpenedRenderer(cellData) {
        const date = moment(cellData).format('DD/MM/YYYY');
        return cellData ? date : '-';
    }

    remainingUsageRenderer(cellData, row) {
        const { state } = row;
        if (!cellData || !row.counter || state === 'closed') return '-';

        // we don't have access to the counter
        // the counter is probably inactive
        if (!this.props.counters[row.counter]) {
            return 'inactive counter';
        }

        const counter_unit = this.props.counters[row.counter].unit;
        const unit_text = COUNTER_UNIT_SHORT_TRANSLATION[counter_unit] || counter_unit;
        const formatted_value = formatNumber(cellData);
        return (
            <span className={`remaining-usage ${(cellData < 0) ? 'negative' : ''}`}>
                {`${formatted_value} ${unit_text}`}
            </span>
        );
    }

    impactStatusRenderer(cellData) {
        const _impact_status = [
            { key: 3, label: i18n.t('immobilised'), className: 'status_immobilized' },
            { key: 1, label: i18n.t('operational'), className: 'status_ok' },
            { key: 2, label: i18n.t('degraded'), className: 'status_degraded' },
        ];
        const impact_status = _.find(_impact_status, (elm) => elm.key === cellData);
        return (
            <span className={impact_status ? impact_status.className : ''}>
                {' '}
                {impact_status ? impact_status.label : '-'}
                {' '}
            </span>
        );
    }

    maintenanceDateStateRenderer(cellData, row) {
        let { status } = cellData;
        const date = moment(cellData.date);

        const date_label = moment(cellData.date)
            .format('DD/MM/YYYY');
        let tolerance_days = null;

        // handle tolerance extra days, the hard way
        const splitted = status.split('_');
        // handle "+ XX" tolerance date in the status
        if ((splitted.length === 2) && !isNaN(splitted[1])) {
            status = splitted[0];
            tolerance_days = ` +${splitted[1]}${i18n.t('day_short_notation')}`;
        }

        const color = status.includes('overdue') ? 'overdue'
            : status.includes('unplanned') ? 'unplanned'
                : status.includes('due') && date.isSameOrBefore(this.in14days) ? 'unplanned'
                    : status.includes('due') ? 'due'
                        : row.is_unconfirmed ? 'unconfirmed' : status;
        status = MaintenanceStateTranslation[status].toUpperCase();

        return (
            <div className="status-block">
                <div className={`status ${color}`}>{status}</div>
                <div className="status-date">
                    <div className="date">
                        {date_label}
                        {(tolerance_days) && <span className="plus_days">{tolerance_days}</span>}
                    </div>
                    <div className="unconfirmed-text">
                        { row.is_unconfirmed ? i18n.t('Unconfirmed intervention') : null}
                    </div>
                </div>
            </div>
        );
    }

    isSelectable(row) {
        return row.state !== 'init';
    }

    expandCallback() {
        this.setState((state) => ({
            expanded: !state.expanded,
        }), () => {
            localStorage.setItem('board.expanded', this.state.expanded);
        });
    }

    render() {
        let events = [];
        const closed_events = [];
        if (this.props.split_on_close) {
            _.map(this.props.maintenance_events, (event) => {
                if (event.state === 'closed') {
                    closed_events.push(event);
                } else {
                    events.push(event);
                }
            });
        } else {
            events = this.props.maintenance_events;
        }
        if (this.props.auto_sort) {
            events.sort(this.props.invert_order ? this.sortByDateDesc : this.sortByDateAsc);
            closed_events.sort(this.props.invert_order ? this.sortByDateDesc : this.sortByDateDesc);
        }

        let data = [events];
        if (!this.props.hideClose && (closed_events.length > 0)) {
            data = data.concat([
                {
                    separator_label: i18n.t('Closed'),
                    expandable_callback: this.expandCallback,
                    expanded: this.state.expanded,
                },
                closed_events,
            ]);
        }

        if (this.props.hideOpen) {
            data = [closed_events];
        }

        const possible_columns = {
            id: {
                key: 'id',
                label: i18n.t('Event Nr'),
                renderer: this.eventIdRenderer,
                sortable: !this.props.notSortable,
            },
            asset: {
                key: 'asset',
                sort_key: 'asset__name',
                label: i18n.t('Asset'),
                renderer: this.assetRenderer,
                sortable: !this.props.notSortable,
            },
            asset_class: {
                key: 'asset',
                sort_key: 'asset__class',
                label: i18n.t('Class'),
                renderer: this.assetClassRenderer,
                sortable: !this.props.notSortable,
            },
            maintenance_type: {
                key: 'maintenance_type',
                label: i18n.t('Type'),
                renderer: this.maintenanceTypeRenderer,
                sortable: !this.props.notSortable,
            },
            description: {
                key: 'description',
                label: i18n.t('Event Description'),
                renderer: this.descriptionRenderer,
                sortable: !this.props.notSortable,
            },
            rex: {
                key: 'rex',
                label: i18n.t('rex'),
                sortable: !this.props.notSortable,
            },
            date_opened: {
                key: 'date_opened',
                label: i18n.t('Date Opened'),
                renderer: this.dateOpenedRenderer,
                sortable: !this.props.notSortable,
            },
            remaining_usage: {
                key: 'remaining_usage',
                sortable: false,
                label: i18n.t('Remaining usage'),
                renderer: this.remainingUsageRenderer,
            },
            next_planned_intervention: {
                key: 'next_planned_intervention',
                sortable: false,
                label: i18n.t('Intervention'),
                renderer: this.interventionRenderer,
            },
            dated_state: {
                key: 'dated_state',
                sort_key: 'sorting_date',
                label: i18n.t('State'),
                custom_class: 'fixed-width-min-content',
                className: 'fixed-width-min-content',
                renderer: this.maintenanceDateStateRenderer,
                sortable: !this.props.notSortable,
            },
            documents: {
                key: 'documents',
                label: i18n.t('documents'),
                renderer: this.smallDocumentRenderer,
                shrink: true,
                sortable: false,
            },
            impact_status: {
                key: 'impact_status',
                label: i18n.t('impact status'),
                renderer: this.impactStatusRenderer,
                sortable: !this.props.notSortable,
            },
        };

        const columns = [];
        if (!this.props.columns) {
            columns.push(possible_columns.id);
            if (this.props.show_asset) {
                columns.push(possible_columns.asset);
            }

            if (this.props.show_asset_class) {
                columns.push(possible_columns.asset_class);
            }

            if (!this.props.hide_maintenance_type) {
                columns.push(possible_columns.maintenance_type);
            }

            // columns.push({key: 'user_opened', label: i18n.t('Created by'), renderer: @userRenderer})
            columns.push(possible_columns.description);

            if (!this.props.hide_rex) {
                columns.push(possible_columns.rex);
            }

            if (this.props.show_date_opened) {
                columns.push(possible_columns.date_opened);
            }

            if (this.props.show_remaining_usage) {
                columns.push(possible_columns.remaining_usage);
            }

            if (!this.props.hide_intervention) {
                columns.push(possible_columns.next_planned_intervention);
            }

            if (!this.props.hide_dated_state) {
                columns.push(possible_columns.dated_state);
            }

            columns.push(possible_columns.documents);
            if (this.props.extra_columns) {
                for (const c of Array.from(this.props.extra_columns)) {
                    columns.push(c);
                }
            }
        } else {
            _.forEach(this.props.columns, (column) => {
                columns.push(possible_columns[column]);
            });
        }

        if (this.props.planning) {
            return (
                <Table
                    is_fetching={this.props.is_fetching}
                    data={this.props.events}
                    data_unique_id="id"
                    columns={columns}
                    theme={this.props.theme}
                    bulk_actions={!this.props.noActions && !this.props.closed ? {
                        subtitle: i18n.t('Please select events to create intervention'),
                        selectedMessage: this.props.selectedMessage,
                        actions: [
                            { label: i18n.t('Create Intervention'), onClick: this.props.toggleInterventionForm },
                        ],
                        select_all_disabled: this.props.bulkSelected && Object.keys(this.props.bulkSelected).length === 0,
                    } : null}
                    bulkSelected={this.props.bulkSelected}
                    onSelect={this.props.selectCallback}
                    title={!this.props.noTitle && i18n.t('Planning de maintenance')}
                    onRowClick={this.props.openDetailCallback ? this.props.openDetailCallback : null}
                    pagination={{
                        links: this.props.links,
                        current_page: this.props.current_page,
                        total_page: this.props.total_page,
                        navigate: this.props.navigate,
                        page_size: parseInt(this.props.page_size, 10),
                        changePageSize: (v) => {
                            this.props.setPageSize(v);
                            this.props.applyFilter();
                        },
                    }}
                    setOrdering={(v) => {
                        this.props.setOrdering(v);
                        this.props.applyFilter();
                    }}
                    noTabletDisplay={this.props.noTabletDisplay}
                    noMobileDisplay={this.props.noMobileDisplay}
                    ordering={this.props.ordering}
                    noPagination={this.props.noPagination}
                    smallPlaceholder={this.props.smallPlaceholder}
                    mobile_arrangement={this.props.mobile_arrangement}
                    tablet_arrangement={this.props.tablet_arrangement || {
                        primary_shorts: [
                            { key: 'id' },
                            { key: 'asset', label: i18n.t('Asset'), renderer: this.assetRenderer },
                            { key: 'asset_class', label: i18n.t('Class'), renderer: this.assetClassRenderer },
                        ],
                        secondary_shorts: [
                            { key: 'maintenance_type' },
                            { key: 'next_planned_intervention' },
                            { key: 'dated_state' },
                            { key: 'documents' },
                        ],
                        primary_long:
                            { key: 'description' },
                        secondary_longs: [
                            { key: 'remaining_usage' },
                        ],
                    }}
                />
            );
        }
        return (
            <FlexTable
                columns={columns}
                data={data}
                selectCallback={!this.props.hide_intervention ? this.props.selectCallback : null}
                isSelectable={this.isSelectable}
                selected={this.props.selected}
                openDetailCallback={this.props.openDetail ? this.props.openDetailCallback : null}
                deleteCallback={this.props.deleteCallback}
                expandCallback={this.props.expandCallback}
                expandActionRenderer={this.props.expandActionRenderer}
                collapseActionRenderer={this.props.collapseActionRenderer}
                expandCellRenderer={this.props.expandCellRenderer}
                expanded={this.props.expanded}
                expandLabel={this.props.expandLabel}
                hoverable={this.props.hoverable}
                customClass="event-list"
            />
        );
    }
}

_MaintenanceEventListClass.defaultProps = {
    openDetail: true,
    hoverable: true,
    hide_intervention: false,
    hide_dated_state: false,
    hide_maintenance_type: false,
    hide_rex: true,
    split_on_close: true,
    auto_sort: true,
    show_asset: false,
    show_asset_class: false,
    show_date_opened: false,
    show_remaining_usage: true,
};

let mapStateToProps = () => {
    const events_selector = maintenanceEventsSelectors.makeMaintenanceEventsListForAsset();
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    mapStateToProps = (state, props) => {
        let counters = {};
        if (props.asset_id) {
            counters = countersSelectors.getCountersForAsset(state, props.asset_id);
        } else {
            counters = countersSelectors.getCounters(state);
        }
        return {
            maintenance_events: (props.maintenance_events != null)
                ? props.maintenance_events : events_selector(state, props.asset_id),
            maintenance_settings: props.maintenance_settings
                || getMaintenanceSettings(state, props.asset_id),
            assets: assetsSelectors.getAssetsInfos(state),
            users: state.users.all,
            counters,
            user_perm_check: usersSelectors.userHasPerm(state),
            user: usersSelectors.getUser(state),

            is_fetching: filteringCollectionsSelectors.isCollectionFetching(state, 'events'),
            events: props.events || filteringCollectionsSelectors.getCollectionForCurrentPage(state, 'events'),
            links: filteringCollectionsSelectors.getCollectionPaginationLinks(state, 'events'),
            current_page: filteringCollectionsSelectors.getCollectionPaginationCurrentPage(state, 'events'),
            total_page: filteringCollectionsSelectors.getCollectionPaginationTotalPage(state, 'events'),
            page_size: filteringCollectionsSelectors.getCollectionFields(state, 'events').page_size,
            ordering: filteringCollectionsSelectors.getCollectionFields(state, 'events').ordering,
        };
    };
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
    navigate: (evt, rel) => {
        evt.preventDefault();
        return dispatch(filteringCollectionsOperations.navigate(rel, 'events'));
    },
    setPageSize: (p) => dispatch(filteringCollectionsOperations.setField('events', 'page_size', p)),
    setOrdering: (p) => dispatch(filteringCollectionsOperations.setField('events', 'ordering', p)),
    applyFilter: () => dispatch(filteringCollectionsOperations.applyFilter('events')),
});

const MaintenanceEventListClass = _MaintenanceEventListClass;
const MaintenanceEventList = connect(mapStateToProps, mapDispatchToProps)(MaintenanceEventListClass);

// Liste des events utilisé par le dashboard et planning
export default MaintenanceEventList;
