/**
 * Created by lpostula on 28/09/17.
 */
import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import './DocumentUploadForm.scss';
import HeaderModal from 'components/headers/headerModal/HeaderModal';
import { ContextDevice, LAYOUT, withMultiDeviceLayout } from 'components/app/MultiDeviceLayout';
import components from 'components';
import { ROFileInput } from './internal/inputs/FileInput';

const { Modal, MODAL_CONSTANTS } = components.containers;

const DocumentUploadForm = (props) => {
    const [_fetched, setFetched] = useState(false);
    const [_displayModal, setDisplayModal] = useState(false);
    const { device } = useContext(ContextDevice);

    const onPopoverClick = (evt, visible) => {
        evt.stopPropagation();
        evt.preventDefault();
        if (visible && !_fetched) {
            props.fetchDocumentsForItem(props.item_id);
            setFetched(true);
        }
        setDisplayModal(visible);
    };

    const handleDocumentUpload = async (evt) => {
        if (props.disabled) {
            return;
        }
        const { files } = evt.target;
        const data = new FormData();
        data.append('file', files[0]);
        try {
            const _settings = {
                method: 'POST',
                headers: {
                    'Cache-Control': 'no-cache',
                    'X-CSRFToken': Cookies.get(window.railfleet_settings.CSRF_COOKIE_NAME),
                },
                body: data,
            };
            const response = await fetch('/api/document.json', _settings);

            if (response.ok) {
                const result = await response.json();
                const d_out = result;
                const document_ids = _.pluck(props.documents, 'id');
                props.updateItem({
                    id: props.item_id, documents: document_ids.concat([d_out.id]),
                });
                d_out[props.item_key] = props.item_id;
                props.setDocumentInStore(d_out);
            } else {
                console.error('File upload failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error while uploading file:', error);
        }
    };

    const document_ids = _.pluck(props.documents, 'id');

    return (
        <div onClick={(evt) => onPopoverClick(evt, true)}>
            <div className="trigger-component-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                    <path d="M12.75 1.273C9.885-.382 6.222.579 4.585 3.415L.43 10.614c-.219.379-.086.864.297 1.085.382.221.87.094 1.089-.285l4.156-7.2c1.2-2.078 3.886-2.783 5.987-1.57 2.102 1.213 2.834 3.892 1.634 5.97l-4.354 7.542c-.764 1.324-2.474 1.772-3.81 1-1.338-.772-1.804-2.476-1.04-3.8L8.346 6.5c.328-.567 1.06-.759 1.633-.428.573.33.773 1.061.446 1.628l-3.76 6.514c-.22.379-.086.864.296 1.085.383.221.87.094 1.089-.285L11.81 8.5c.764-1.323.298-3.027-1.04-3.8-1.336-.771-3.046-.323-3.81 1l-3.958 6.856c-1.2 2.08-.468 4.758 1.634 5.971 2.101 1.213 4.787.508 5.987-1.57l4.354-7.542c1.638-2.836.638-6.488-2.227-8.142z" />
                </svg>
                <span className="glyphicon glyphicon-notify" />
                <div className={`count-outline ${props.count !== 0 ? 'non-zero' : ''}`}>
                    <span className="count-text">
                        { props.count }
                    </span>
                </div>
            </div>
            {
                _displayModal && (
                    <Modal
                        className="document-popover"
                        layout={device !== LAYOUT.DESKTOP ? MODAL_CONSTANTS.LAYOUT.FULL : LAYOUT.DESKTOP}
                        position={device !== LAYOUT.DESKTOP ? MODAL_CONSTANTS.POSITION.LEFT : MODAL_CONSTANTS.POSITION.CENTER}
                        handleCloseInside
                    >
                        <div className="document_upload_popover">
                            <div className="document_upload_popover_content">
                                <HeaderModal
                                    title={i18n.t('Attachment(s) for M{{event_id}}', {
                                        event_id: props.item_id,
                                    })}
                                    onCloseModal={(evt) => onPopoverClick(evt, false)}
                                />
                                <div>
                                    <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                        <ROFileInput
                                            value={document_ids}
                                            values={props.documents}
                                            deleteAction={props.deleteDocument}
                                            deletable={!props.disabled}
                                            custom_class="col-xs-12 col-sm-6"
                                            multi
                                        />
                                    </div>
                                    <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                        <input
                                            type="file"
                                            name={`file_${props.item_id}`}
                                            id={`file_${props.item_id}`}
                                            className="document-input-file"
                                            onClick={(evt) => {
                                                if (props.disabled) {
                                                    evt.preventDefault();
                                                }
                                                evt.stopPropagation();
                                            }}
                                            onChange={handleDocumentUpload}
                                        />
                                        <label
                                            className={`document-input-file-label btn btn-default btn-block btn-lg ${props.disabled ? 'disabled' : ''}`}
                                            htmlFor={`file_${props.item_id}`}
                                            onClick={(evt) => {
                                                if (props.disabled) {
                                                    evt.preventDefault();
                                                }
                                                evt.stopPropagation();
                                            }}
                                        >
                                            {i18n.t('Upload a file')}
                                            <span style={{ marginLeft: 10 }} className="glyphicon glyphicon-upload" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </div>
    );
};

DocumentUploadForm.propTypes = {
    fetchDocumentsForItem: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    setDocumentInStore: PropTypes.func.isRequired,
    item_id: PropTypes.number.isRequired,
    item_key: PropTypes.string.isRequired,
    documents: PropTypes.object.isRequired,
    upload_url: PropTypes.string.isRequired,
    count: PropTypes.number,
};

DocumentUploadForm.defaultProps = {
    count: 0,
};

export default withMultiDeviceLayout(DocumentUploadForm);
