import React, { useEffect, useState } from 'react';
import './LocoPreparationForm.scss';
import i18n from 'i18next';
import moment from 'moment-timezone';
import scrollToTop from '../../../utils/scrollToTop';
import usePost from '../../../components/hooks/usePost';
import MultiDeviceLayout from '../../../components/app/MultiDeviceLayout';
import FormManager from '../../../components/forms/RailForms/FormManager';
import StepManager from '../../../components/forms/RailForms/StepManager';
import Step from '../../../components/forms/RailForms/step/Step';
import FormQcm, { TypeRadio } from '../../../components/forms/RailForms/formQcm/FormQcm';
import FormTextInput from '../../../components/forms/RailForms/formTextInput/FormTextInput';
import FocusableFrame from '../../../components/frames/focusableFrame/FocusableFrame';
import formatStrToDict from '../../../utils/formatStrToDict';
import { THEMES } from '../../../components/filters/selectFilter/SelectFilter';
import FormDateTime from '../../../components/forms/RailForms/formDateTime/FormDateTime';
import LocoPreparationDetail from '../../details/locoPreparationDetail/LocoPreparationDetail';
import OperationalStatusForm from '../operationalStatusForm/OperationalStatusForm';
import Button from '../../../components/buttons/button/Button';
import TextAction from '../../../components/buttons/textAction/TextAction';

import useArrayToDict from '../../../components/hooks/useArrayToDict';

const ValidationView = (props) => {
    const [_displaySubmit, setDisplaySubmit] = useState(true);
    const [_isSubmiting, setIsSubmiting] = useState(false);
    const onChange = (arg) => {
        setDisplaySubmit(!arg);
    };
    const valueError = props.errorApi?.steps;

    return (
        <>
            <div>
                { valueError && (
                    <div className="form-validation-error">
                        <div className="form-error-message">
                            {`${i18n.t('Error')}: ${valueError}`}
                        </div>
                    </div>
                )}
                <FocusableFrame
                    title={i18n.t('Do you want to change operational status ?')}
                    className="loco-preparation-form-status"
                >
                    <OperationalStatusForm
                        onChange={onChange}
                        asset_id={props.asset_id}
                        textSubmit={i18n.t('Finish and change the operational status')}
                        operationalStatusCallback={() => props.onSubmit(props.formatedData)}
                        noHeader
                        noLabel
                        {...props}
                    />
                    {
                        _displaySubmit && (
                            <div className="loco-preparation-form-submit">
                                <TextAction
                                    text={i18n.t('Previous')}
                                    disabled={_isSubmiting}
                                    onClick={() => props.onPrevious()}
                                />
                                <TextAction
                                    text={i18n.t('Finish')}
                                    primary
                                    disabled={_isSubmiting}
                                    onClick={() => {
                                        setIsSubmiting(true);
                                        props.onSubmit(props.formatedData);
                                    }}
                                />
                            </div>
                        )
                    }
                </FocusableFrame>
            </div>
            <LocoPreparationDetail
                steps={props.steps}
                preparation_detail={props.formatedData}
            />
        </>
    );
};

const LocoPreparationFormComponent = (props) => {
    const [_errorApi, setErrorApi] = useState(null);
    const { postAll, hasError, isLoading } = usePost();
    const [_formatedData, setFormatedData] = useState(null);
    const [_orderedSteps, setOrderedSteps] = useState([]);
    const _steps = useArrayToDict(props.steps, 'key');

    useEffect(() => {
        const orderedSteps = [];
        if (props.steps && props.steps.length > 0) {
            const unordered_keys = Object.keys(props.steps).map((key) => [key, props.steps[key].order]);
            const ordered_keys = unordered_keys.sort((a, b) => a[1] - b[1]);
            for (const elm of ordered_keys) {
                const step = props.steps[elm[0]];
                orderedSteps.push(step.key);
            }
        }
        setOrderedSteps(orderedSteps);
    }, [props.steps]);

    const format_data = (data) => {
        const formated_data = {};
        const popped_data = { ...data };
        formated_data.timestamp = data.timestamp.toISOString();
        formated_data.asset = props.asset_id;
        formated_data.type = props.type;
        delete popped_data.timestamp;

        const steps = formatStrToDict(popped_data);
        formated_data.steps = steps;
        return formated_data;
    };

    useEffect(() => {
        if (hasError) {
            setErrorApi(hasError);
            scrollToTop();
        } else {
            setErrorApi(null);
        }
    }, [hasError]);

    const validation = (_data) => {
        if (isLoading) return;
        const formated_data = format_data(_data);
        setFormatedData(formated_data);
    };

    const onPrevious = () => {
        setFormatedData(null);
    };

    const onSubmit = (formated_data) => {
        if (props.loginless) {
            props.create_preparation(formated_data).then((d) => {
                if (d && !d.error) {
                    if (props.callback) props.callback(d);
                } else if (d?.error) {
                    setErrorApi(d);
                    scrollToTop();
                }
            });
        } else {
            postAll('/api/preparation.json', formated_data).then((d) => {
                if (d && !d.error) {
                    if (props.callback) props.callback(d);
                } else if (d?.error) {
                    setErrorApi(d);
                    scrollToTop();
                }
            });
        }
    };

    const validateStep = (data, step) => {
        if (!data || !step) return null;
        // make sure that there is a value defined for every field of this step
        if (Object.keys(data).length === step.length) return data;
        return null;
    };
    const generateSteps = (pSteps) => {
        let focus = false;
        let first = true;
        const fields = _orderedSteps.map((key, index) => {
            const step = pSteps[key];
            const field = (
                <Step
                    key={key}
                    id={index + 1}
                    name={step.label}
                >
                    {
                        first && (
                            <FocusableFrame
                                id={`${step.key}_date`}
                                className="date"
                                title={i18n.t('infos')}
                            >
                                <FormDateTime
                                    id="timestamp"
                                    label={i18n.t('Date')}
                                    defaultValue={moment()}
                                    theme={THEMES.FORM_LIGHT}
                                    required
                                />
                            </FocusableFrame>
                        )
                    }
                    {
                        step.conformity_checks && !_.isEmpty(step.conformity_checks)
                        && (
                            <FocusableFrame
                                className="conformity_checks"
                                id={`${step.key}_conformity_checks`}
                                title={`${step.label}*`}
                            >
                                <FormQcm
                                    id={`${step.key}__conformity_checks`}
                                    options={[
                                        { key: '0', label: i18n.t('Conform'), type: TypeRadio.VALID },
                                        { key: '1', label: i18n.t('Not conform'), type: TypeRadio.INVALID },
                                    ]}
                                    validate={(data) => validateStep(data, step.conformity_checks)}
                                    data={step.conformity_checks}
                                    id_getter={(elm) => elm.key}
                                    labelizer={(elm) => elm.label}
                                    selectAllText={i18n.t('Mark everything conform')}
                                    boolean="0"
                                    required
                                />
                                <FormTextInput
                                    id={`${step.key}__conformity_checks_comment`}
                                    label={i18n.t('Description')}
                                    large
                                    lockSize
                                />
                            </FocusableFrame>
                        )
                    }
                    {
                        // eslint-disable-next-line no-loop-func
                        step.level_checks.map((level_check) => {
                            focus = !focus;
                            return (
                                <FocusableFrame
                                    id={`${step.key}_level_checks_${level_check.key}`}
                                    key={`${step.key}_level_checks_${level_check.key}`}
                                    className={`${step.key}_level_checks_${level_check.key}`}
                                    title={`${level_check.label}*`}
                                    focus={focus}
                                >
                                    <FormQcm
                                        id={`${step.key}__level_checks__${level_check.key}`}
                                        options={level_check.options}
                                        data={level_check.entries}
                                        validate={(data) => validateStep(data, level_check.entries)}
                                        id_getter={(elm) => elm.key}
                                        labelizer={(elm) => elm.label}
                                        required
                                    />
                                    <FormTextInput
                                        id={`${step.key}__level_checks_comments__${level_check.key}`}
                                        label={i18n.t('Description')}
                                        large
                                        lockSize
                                    />
                                </FocusableFrame>
                            );
                        })
                    }
                </Step>
            );
            first = false;
            return field;
        });
        return fields;
    };

    if (!_orderedSteps || _orderedSteps.length === 0) return null;
    const steps = generateSteps(_steps);
    return (
        <div className={`form-main ${props.device} loco-preparation-form`}>
            <FormManager
                onSubmit={validation}
                disabled={isLoading}
                apiErrors={_errorApi}
            >
                <StepManager
                    textSubmitButton={i18n.t('Next')}
                    previousIsText
                    confirmationView={!!_formatedData}
                >
                    { steps.map((step) => step) }
                </StepManager>
                {
                    _formatedData && (
                        <ValidationView
                            asset_id={props.asset_id}
                            formatedData={_formatedData}
                            onPrevious={onPrevious}
                            onSubmit={onSubmit}
                            steps={props.steps}
                            errorApi={_errorApi}
                            {...props}
                        />
                    )
                }
            </FormManager>
        </div>
    );
};

const LocoPreparationForm = (props) => (
    <MultiDeviceLayout>
        <LocoPreparationFormComponent
            {...props}
        />
    </MultiDeviceLayout>
);

export default LocoPreparationForm;
