import React from 'react';
import {
    withGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

class ReCaptchaComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
        };
        this.intervalId = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.token !== this.state.token) {
            this.props.onChange(this.state.token);
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    async componentDidMount() {
        await this.requestToken();
        this.intervalId = setInterval(() => this.requestToken(), 60 * 1000);
    }

    async requestToken() {
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('defect_report');
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
            token,
        });
    }

    render() {
        return null;
    }
}

const YourReCaptchaComponent = withGoogleReCaptcha(ReCaptchaComponent);

export default YourReCaptchaComponent;
