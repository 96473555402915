import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment-timezone';
import components from 'components';
import { maintenanceEventsOperations, maintenanceEventsSelectors } from 'railfleet_state/ducks/maintenance_events';
import { interventionsOperations, interventionsSelectors } from 'railfleet_state/ducks/interventions';

import './KeepOpenForm.scss';
import FormCheckbox from '../../../components/forms/RailForms/formCheckbox/FormCheckbox';

const {
    FormManager,
    FormTextInput,
    CutForm,
    FormDateTime,
} = components.forms;

const { THEMES } = components.selectors;
const { FocusableFrame } = components.frames;

const { MultiDeviceLayout } = components.app;

const KeepOpenFormComponent = (props) => {
    const [_iea, setIea] = useState(null);

    useEffect(() => {
        if (props.intervention_id) {
            props.fetchInterventionDetail(props.intervention_id);
        }
    }, [props.intervention_id]);

    useEffect(() => {
        let selectedIea = null;
        if (props.intervention) {
            for (const iea of props.intervention.interventioneventaction) {
                if (iea.event === props.event_id) selectedIea = iea;
            }
        }
        setIea(selectedIea);
    }, [props.intervention]);

    const format_data = (data) => {
        const formated_data = { ...data };
        formated_data.id = props.event_id;
        formated_data.date_closed = data.date_closed.toISOString();
        formated_data.date_started = data.date_started.toISOString();
        formated_data.rex = data.comment;
        formated_data.result = 'keep_open';
        formated_data.intervention = props.intervention_id;
        formated_data.rts_question_derogation = formated_data.rts_question_derogation === 'true';
        return formated_data;
    };

    const onSubmit = (arg) => {
        if (props.locked) return;
        if (props.setLocked) props.setLocked(true);
        const payload = format_data(arg);
        const method = (_iea && _iea.result === 'keep_open') ? props.updateEvent : props.processEvent;
        method(payload).then((d) => {
            if (props.setLocked) props.setLocked(false);
            if (d.payload && !d.error) {
                if (props.callback) props.callback(d);
            }
        });
    };

    const defaultValues = {
        date_started: (
            props.event.date_started && moment(props.event.date_started)
        ) || props.date_started || moment(),
        date_closed: (props.event.date_closed && moment(props.event.date_closed)) || moment(),
        comment: _iea && _iea.comment,
        rts_question_derogation: _iea && _iea.rts_question_derogation,
        rts_question_derogation_comment: _iea && _iea.rts_question_derogation_comment,
    };

    return (
        <div className={`keep-open-form ${props.device}`}>
            <div className="data-form">
                <FormManager
                    onSubmit={onSubmit}
                    textSubmitButton={props.textSubmit}
                    onCancel={props.onCancel}
                    disabled={props.locked}
                >
                    <CutForm
                        device={props.device}
                    >
                        <FormDateTime
                            id="date_started"
                            label={i18n.t('Date started')}
                            defaultValue={
                                defaultValues.date_started
                            }
                            theme={THEMES.FORM_LIGHT}
                            fillFlex
                            required
                        />
                        <FormDateTime
                            id="date_closed"
                            label={i18n.t('Date closed')}
                            defaultValue={
                                defaultValues.date_closed
                            }
                            theme={THEMES.FORM_LIGHT}
                            fillFlex
                            required
                        />
                        <FormTextInput
                            id="comment"
                            label={i18n.t('Comment')}
                            defaultValue={
                                defaultValues.comment
                            }
                            theme={THEMES.FORM_LIGHT}
                            large
                            required
                        />
                    </CutForm>
                    {
                        props.fromIntervention
                        && (
                            <div className="rts-part">
                                <CutForm
                                    device={props.device}
                                >
                                    <FormCheckbox
                                        id="rts_question_derogation"
                                        selectedValue={(defaultValues && defaultValues.rts_question_derogation ? 'true' : 'false') || 'false'}
                                        label={i18n.t('Was there a derogation against technical instructions during this intervention?')}
                                        values={[
                                            {
                                                id: 'true',
                                                name: i18n.t('Yes'),
                                            },
                                            {
                                                id: 'false',
                                                name: i18n.t('No'),
                                            },
                                        ]}
                                        trigger="false"
                                        labelizer={(item) => item.name}
                                        required
                                        disabled={defaultValues && defaultValues.rts_question_derogation}
                                    />
                                    <FormTextInput
                                        id="rts_question_derogation_comment"
                                        defaultValue={defaultValues && defaultValues.rts_question_derogation_comment}
                                        label={i18n.t('Comment')}
                                        large
                                        lockSize
                                        disabled={defaultValues && defaultValues.rts_question_derogation_comment}
                                    />
                                </CutForm>
                            </div>
                        )
                    }
                </FormManager>
            </div>
        </div>
    );
};

const FormComponent = (props) => (
    <MultiDeviceLayout>
        <KeepOpenFormComponent
            {...props}
        />
    </MultiDeviceLayout>
);

const KeepOpenForm = (props) => {
    if (props.framed) {
        return (
            <FocusableFrame
                light
                noTabletDisplay={props.noTabletDisplay}
            >
                <FormComponent
                    {...props}
                />
            </FocusableFrame>
        );
    }
    return (
        <FormComponent
            {...props}
        />
    );
};

const mapStateToProps = (state, props) => {
    const { event_id } = props;
    const { intervention_id } = props;
    const event = event_id && maintenanceEventsSelectors.getMaintenanceEvent(state, event_id);
    const asset_id = event && event.asset;
    const intervention = intervention_id
        ? interventionsSelectors.getInterventionById(state, intervention_id)
        : null;
    return {
        asset_id,
        event: event || {},
        intervention: intervention || {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    processEvent: (values) => dispatch(
        maintenanceEventsOperations.processMaintenanceEvent(values),
    ),
    updateEvent: (payload) => dispatch(maintenanceEventsOperations.updateMaintenanceEvent(payload)),
    fetchInterventionDetail: (intervention_id) => dispatch(
        interventionsOperations.fetchDetails(intervention_id),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeepOpenForm);
